import { Box, Button } from '@stitch-fix/mode-react';
import classNames from 'classnames';
import { buttonColorToButtonVariant } from '../ModuleFooter/ModuleFooter';
import styles from './styles.module.scss';
import { useStickyCtaContext } from './useStickyCtaContext';

export type StickyCtaProps =
  | {
      cta: {
        title: string;
        href: string;
      };
      buttonColor?: string;
      CtaButton?: never;
    }
  | {
      cta?: never;
      buttonColor?: never;
      CtaButton: React.ReactNode;
    };

// The StickyCta component is hidden when the Hero component is in view.
// To use StickyCta, wrap the parent element  of the Hero and the StickyCta in <StickyCtaContextProvider>.

export const StickyCta = ({ cta, buttonColor, CtaButton }: StickyCtaProps) => {
  const { isStickyCtaHidden } = useStickyCtaContext();

  return (
    <Box
      className={classNames(styles.stickyCtaContainer, {
        [styles.hidden]: isStickyCtaHidden,
        [styles.shown]: !isStickyCtaHidden,
      })}
    >
      {cta ? (
        <Button
          as="a"
          variant={buttonColorToButtonVariant(buttonColor)}
          width={{ sm: 'fill' }}
          href={cta.href}
        >
          {cta.title}
        </Button>
      ) : (
        CtaButton
      )}
    </Box>
  );
};
